import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';

/**
 * External dependencies.
 */
import React, { Fragment } from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import parse from 'html-react-parser';

/**
 * Internal dependencies.
 */
import SEO from '@blocks/seo/seo';

const BlogPostTemplate = ({ data: { previous, next, page: post } }) => {
	const featuredImage = {
		fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
		alt: post.featuredImage?.node?.alt || ``,
	};

	return (
		<Fragment>
			<SEO
				title={post.title}
				description={post.excerpt}
			/>

			<article
				className="blog-post"
				itemScope
				itemType="http://schema.org/Article"
			>
				<header>
					<h1 itemProp="headline">{parse(post.title)}</h1>

					<p>{post.date}</p>

					{featuredImage?.fluid && (
						<Image
							fluid={featuredImage.fluid}
							alt={featuredImage.alt}
							style={{ marginBottom: 50 }}
						/>
					)}
				</header>

				{!!post.content && (
					<section
						itemProp="articleBody"
						dangerouslySetInnerHTML={{ __html: post.content }}
					/>
				)}
			</article>

			<nav className="blog-post-nav">
				<ul
					style={{
						display: `flex`,
						flexWrap: `wrap`,
						justifyContent: `space-between`,
						listStyle: `none`,
						padding: 0,
					}}
				>
					<li>
						{previous && (
							<Link
								to={previous.uri}
								rel="prev"
							>
								← {parse(previous.title)}
							</Link>
						)}
					</li>

					<li>
						{next && (
							<Link
								to={next.uri}
								rel="next"
							>
								{parse(next.title)} →
							</Link>
						)}
					</li>
				</ul>
			</nav>
		</Fragment>
	);
};

export const query = graphql`
    query BlogPostById(
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        page: wpPost(id: { eq: $id }) {
            id
            excerpt
            content
            title
            date(formatString: "MMMM DD, YYYY")

            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1000, quality: 100) {
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        }

        previous: wpPost(id: { eq: $previousPostId }) {
            uri
            title
        }

        next: wpPost(id: { eq: $nextPostId }) {
            uri
            title
        }

        menu: wpMenu(locations: {eq: GATSBY_HEADER_MENU}) {
            ...PageMenuFragment
        }

        contact: wp {
            ...PageContactFragment
        }
    }
`;

export default BlogPostTemplate;
